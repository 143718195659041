import React, { useCallback, useContext, useMemo } from 'react';
import {
    NotificationListActionButton,
    NotificationListItemActions,
    NotificationListItemContainer,
    NotificationListItemContent,
    NotificationListItemContentSubText,
    NotificationListItemContentTitle,
    NotificationListItemRightPart,
    NotificationListRequestTime
} from './style';
import Translation from '../../../../Translation';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useTranslation } from 'react-i18next';
import { Translate } from '../../../../../internationalization/translate';
import UiDataContext from '../../../../../context/UiContext/context';
import { errorToast, successToast } from '../../../../Utils/Toast';
import { NotificationType } from '../d';
import moment from 'moment';
import useRemoveNotificationMutation from '../../../../../core/api/hooks/mutations/notifications/useRemoveNotificationMutation';

const NotificationListItem = ({ item, setRefetch }: any) => {
    const {t} = useTranslation();
    const { setLoading, resetLoading } = useContext(UiDataContext);
    const removeNotificationMutation = useRemoveNotificationMutation();

    const [title, text, id, createdAt] = useMemo(() => [
        (() => {
            switch (item.notificationType) {
                case NotificationType.ORDER_ITEM_FINISHED:
                case NotificationType.ORDER_FINISHED:
                case NotificationType.ORDER_ABORTED:
                case NotificationType.ORDER_CUSTOMER_NOT_SHOW:
                    return Translate.notifications;
            }
        })(),
        (() => {
            switch (item.notificationType) {
                case NotificationType.ORDER_ITEM_FINISHED:
                    return t(Translate.notificationOrderItemFinishedText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}` });
                case NotificationType.ORDER_FINISHED:
                    return t(Translate.notificationOrderFinishedText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}` });
                case NotificationType.ORDER_ABORTED:
                    return t(Translate.notificationOrderAbortedText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}` });
                case NotificationType.ORDER_CUSTOMER_NOT_SHOW:
                    return t(Translate.notificationOrderCustomerNotShowText, { dailyNumber: item.value?.dailyNumber, driver: `${item?.value?.driver?.firstName} ${item?.value?.driver?.lastName}` });
            }
        })(),
        item.id,
        item.value?.time ? moment(new Date(item.value?.time)).tz('Europe/Belgrade').format('HH:mm') : ''
    ], [item, t]);


    const removeNotificationRequest = useCallback(async ()=> {
        if(!id) return; if(!id) return;
        try {
            setLoading();
            const result = await removeNotificationMutation.mutateAsync(id);
            if (result.status === 200) {
                successToast(t(result.message));
                setRefetch();
            }
        }catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        }finally {
            resetLoading();
        }
    },[id, resetLoading, setLoading, setRefetch, t, removeNotificationMutation]);
    
    return (
        <NotificationListItemContainer>
            <NotificationListItemContent>
                <Translation use={title} as={NotificationListItemContentTitle} />
                <NotificationListItemContentSubText>{text}</NotificationListItemContentSubText>
            </NotificationListItemContent>
            <NotificationListItemRightPart>
                <NotificationListRequestTime>{createdAt}</NotificationListRequestTime>
                <NotificationListItemActions>
                    <NotificationListActionButton  title={'Obriši'} onClick={removeNotificationRequest}>
                        <CancelOutlinedIcon />
                    </NotificationListActionButton>
                </NotificationListItemActions>
            </NotificationListItemRightPart>

        </NotificationListItemContainer>
    );
};

export default NotificationListItem;