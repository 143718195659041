export enum UI_EVENTS {
  UI_SET_LOADING='UI_SET_LOADING',
  UI_RESET_LOADING='UI_RESET_LOADING',

  WS_SET_SOCKET = 'WS_SET_SOCKET',
  WS_SET_MESSAGE = 'WS_SET_MESSAGE',
  WS_REMOVE_MESSAGE = 'WS_REMOVE_MESSAGE',
  WS_REMOVE_MESSAGE_BY_TYPE = 'WS_REMOVE_MESSAGE_BY_TYPE',
  WS_REMOVE_MESSAGE_TYPE_ARRAY = 'WS_REMOVE_MESSAGE_TYPE_ARRAY',
  WS_CLEAR_STATE = 'WS_CLEAR_STATE',
}

export type TUiReducerAction = {
  type: UI_EVENTS;
  payload?: number | string | boolean | null | any;
}

export type TUiReducer = {
  isLoading?: boolean,
  text?: string
  timer?: number
  isSkeleton?: boolean
  isMini?: boolean
  socket?: WebSocket
  messages: any[]
}
