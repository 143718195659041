import { TUiReducer, TUiReducerAction, UI_EVENTS } from 'context/UiContext/d';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import UiDataContext from 'context/UiContext/context';
import { guid } from '../../utils';
import { NotificationType } from '../../components/appbar/appbarRight/Notifications/d';


const reducer = (state: TUiReducer, action: TUiReducerAction) => {

  switch (action.type) {
    default:
      return state;

    case UI_EVENTS.UI_SET_LOADING: {
      const { timer, isSkeleton, text, isMini } = action.payload || {};
      if (typeof isMini !== 'undefined') return {
        ...state,
        loading: {
          isLoading: false,
          isMini,
        }
      };
      if (typeof isSkeleton !== 'undefined') {
        return {
          ...state,
          loading: {
            isLoading: false,
            isSkeleton,
          }
        };
      }
      return {
        ...state,
        isLoading: true,
        timer: timer ? Number(timer) : void (0),
        isSkeleton: void (0),
        isMini: void (0),
        text
      };
    }

    case UI_EVENTS.UI_RESET_LOADING: {
      if (!state?.isLoading && !state?.timer && !state?.isSkeleton && !state?.isMini) return state;
      return {
        ...state,
        isLoading: false,
        timer: void (0),
        isSkeleton: false,
        isMini: false,
        text: void (0)
      };
    }

    case UI_EVENTS.WS_SET_SOCKET: {
      return {
        ...state,
        socket: action.payload
      };
    }

    case UI_EVENTS.WS_SET_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, {
          id: guid(),
          ...action.payload
        }]
      };
    }

    case UI_EVENTS.WS_REMOVE_MESSAGE: {
      const index = [...state.messages].findIndex(x=> x.id === action.payload);
      if(index === -1) return state;
      const messages = [...state.messages];
      messages.splice(index, 1);
      return {
        ...state,
        messages
      };
    }

    case UI_EVENTS.WS_REMOVE_MESSAGE_BY_TYPE: {
      const data = [...state.messages].filter(x=> x.type === action.payload);
      if(!data?.length) return state;
      const messages = [...state.messages].filter(x => x.type !== action.payload);
      return {
        ...state,
        messages
      };
    }

    case UI_EVENTS.WS_REMOVE_MESSAGE_TYPE_ARRAY: {
      const data = [...state.messages].filter(x=>  [
          NotificationType.ORDER_FINISHED,
          NotificationType.ORDER_ITEM_FINISHED,
          NotificationType.ORDER_ABORTED,
          NotificationType.ORDER_CUSTOMER_NOT_SHOW
      ].includes(x.type));
      if(!data?.length) return state;
      const messages = [...state.messages].filter(x => ![
        NotificationType.ORDER_FINISHED,
        NotificationType.ORDER_ITEM_FINISHED,
        NotificationType.ORDER_ABORTED,
        NotificationType.ORDER_CUSTOMER_NOT_SHOW
      ].includes(x.type));
      return {
        ...state,
        messages
      };
    }

    case UI_EVENTS.WS_CLEAR_STATE: {
      return {
        ...state,
        messages: [],
        socket: undefined
      };
    }

  }
};

//const WS_URL = 'wss://api.stg.dingdong.rs/ws';
const WS_URL = 'ws://localhost:9821';

const useUiContext = () => {
  const dataReducerStartState = useRef({
    isLoading: false,
    timer: void (0),
    isSkeleton: false,
    isMini: false,
    text: void (0),
    messages: []
  });

  const [state, dispatch] = useReducer(reducer, dataReducerStartState.current as TUiReducer);

  const setLoading = useCallback((payload?: TUiReducer) => {
    dispatch({
      type: UI_EVENTS.UI_SET_LOADING,
      payload
    });
  }, [dispatch]);

  const resetLoading = useCallback(() => {
    dispatch({
      type: UI_EVENTS.UI_RESET_LOADING
    });
  }, [dispatch]);

  const wsRemoveMessage = useCallback((payload: string) =>dispatch({
    type: UI_EVENTS.WS_REMOVE_MESSAGE,
    payload
  }),[dispatch]);

  const wsRemoveOrderMessages = useCallback(()=> dispatch({
    type: UI_EVENTS.WS_REMOVE_MESSAGE_TYPE_ARRAY
  }),[dispatch]);

  const wsRemoveMessagesByType = useCallback((payload: string) => dispatch({
    type: UI_EVENTS.WS_REMOVE_MESSAGE_BY_TYPE,
    payload
  }), [dispatch]);

  useEffect(() => {
    const ws = new WebSocket(WS_URL);


    ws.onmessage = (message) => {
      const messageData = JSON.parse(message?.data);
      if(messageData.eventType && messageData?.eventType === 'ADMIN') {
        dispatch({
          type: UI_EVENTS.WS_SET_MESSAGE,
          payload: JSON.parse(message.data)
        });
      }
    };

    ws.onclose = () => {
      dispatch({
        type: UI_EVENTS.WS_CLEAR_STATE
      });
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    dispatch({
      type: UI_EVENTS.WS_SET_SOCKET,
      payload: ws
    });


    return () => {
      ws.close();
      dispatch({
        type: UI_EVENTS.WS_CLEAR_STATE
      });
    };
  }, []);



  return useMemo(()=> ({
    ...state,
    setLoading,
    resetLoading,
    wsRemoveMessage,
    wsRemoveMessagesByType,
    wsRemoveOrderMessages
  }),[
    state,
    setLoading,
    resetLoading,
    wsRemoveMessage,
    wsRemoveMessagesByType,
    wsRemoveOrderMessages
  ]);
};


export type TUiContextType = ReturnType<typeof useUiContext>;
const UiContextContainer = ({
                              children
                            }: any) => {

  const providerData = useUiContext();

  return (
    <UiDataContext.Provider value={providerData}>
      {children}
    </UiDataContext.Provider>
  );
};

export default UiContextContainer;
